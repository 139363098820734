import { useEffect, useRef, useState } from "react";
import { albumDataInterface, scheduleProductInterface, uploadRequestfileDataInterface } from "./static/interface/ProductionList.interface";
import { callAxios, customToast, dev_console, formatDate, imageFilesUpload, isSuccess, isUseTestData, printStateMsg, whatIsMode } from "../../Common/ts/commonTools";
import CSS from "./static/css/ProductionRequest.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import usePhotoProductDataREST from "./hooks/usePhotoProductDataREST.hook";
import ProductionRequestThemplete from "./components/ProductionRequestThemplete/ProductionRequestThemplete";
import useProductionRequestinitialData from "./hooks/useProductionRequestinitialData.hook";
import { chunkImageUploadDataInterface, defaultApiReaponseInterface } from "../../Common/interface/Common.interface";
import { toast } from "react-toastify";
import { userDataStorageManagerClass } from "./components/AlbumProductInputContainer/static/ts/AlbumProductInputContainer.tools";
import { addressDataInterface } from "../../Component/Elements/Postcode/interface/useDaumPostcode.interface";

const userDataStorageManager = new userDataStorageManagerClass();

const ProductionRequest = () => {
    // 로딩처리
    const [isUploading, setIsUploading] = useState<boolean>(false);

    // 각 폴더들에 대한 ref들 -> 최종적으로 서버에 요청을 보내서 저장할 때 스크롤을 컨트롤해야하기 때문에 외부에서 관리
    const cardRefs = useRef<Array<HTMLDivElement | null>>([]); 

    const navigate = useNavigate();

    const location = useLocation();

    // 제품 정보가 없을경우 내보낸다.
    if(!(!!location.state) || !(!!location.state?.production)){
        // navigate를 사용할경우 이 조건문이 감지되어도 일단 코드를 끝까지 읽어버린것 같다. -> 이에따라 에러 발생
        // 이를 처리하기위해 너무 불필요한 작업이 많이 필요함... 그래서 그냥 브라우져레벨에서 컨트롤하는중
        window.location.href = "/productionList";
    }

    const {
        production,
        isReRequest,
    } = location.state as {
        production: scheduleProductInterface;
        isReRequest: boolean;
    };

    const {
        selectedOptionList, 
        setSelectedOptionList, 
        request, 
        setRequest, 
        isSatisfiedPhotoNums, 
        setIsSatisfiedPhotoNums, 
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        albumData, 
        setAlbumData,
        choicesAvailableNum,
        isAlbumProduct,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        folderList,
        imgFiles, 
        setImgFiles,
        isUseMultiFolder,
        album_save_id,
        contract_product_id,
    } = useProductionRequestinitialData(production);

    // 옵션정보 호출 실패
    useEffect(() => {
        if(photoProductOptionDataListError){
            customToast.error({msg: "제품의 옵션 정보 호출에 실패했습니다."});
            navigate("/productionList");
        }
    }, [photoProductOptionDataListError])

    // 상품 목록 재 갱신을 위한 refectch 함수들
    const {
        photoProductDataListRefetch: photoProductDataListRefetchAll
    } = usePhotoProductDataREST({
        search_type: 0
    });

    // 상품 목록 재 갱신을 위한 refectch 함수들
    const {
        photoProductDataListRefetch: photoProductDataListRefetchNeedRequest
    } = usePhotoProductDataREST({
        search_type: 1
    });

    // 상품 목록 재 갱신을 위한 refectch 함수들
    const {
        photoProductDataListRefetch: photoProductDataListRefetchRequestDone
    } = usePhotoProductDataREST({
        search_type: 2
    });

    // 미완료된 항목이 있는지 검증하는 함수
    const checkFalseValue = () => {
        // 객체의 key-value 쌍을 배열로 변환
        const entries = Object.entries(isSatisfiedPhotoNums);
      
        // 가장 먼저 감지된 value가 false인 key 찾기
        const firstFalseKey = entries.find(([key, value]) => value === false);
      
        if (firstFalseKey) {
            const [key] = firstFalseKey;
            return parseInt(key); // 필요에 따라 key 값을 반환
        } else {
            return null; // false 값이 없으면 null 반환
        }
    };

    // 서버에 업로드 요청
    const handleUpload = async () => {
        let check_result = checkFalseValue();
        if(check_result !== null){
            dev_console.error("check_result");
            dev_console.error(check_result);

            if(check_result > 0){
                customToast.error({msg: "필요한 수 만큼 사진이 선택되지 않은 항목이 있습니다."});
                scrollToFirstFalseItem(check_result);
            }else{
                customToast.error({msg: "필요한 수 만큼 사진이 선택되지 않았습니다."});
            }

            return ;
        }

        if(isNaN(choicesAvailableNum.min) || isNaN(choicesAvailableNum.max)){
            customToast.error({msg: "선택해야할 사진의 수가 설정되지 않았습니다.\n계약하신 지점으로 문의해주세요."});
            return ;
        }

        if(choicesAvailableNum.min < 1 || choicesAvailableNum.max < 1){
            customToast.error({msg: "선택해야할 사진의 수가 설정되지 않았습니다.\n계약하신 지점으로 문의해주세요."});
            return ;
        }

        if(choicesAvailableNum.max < choicesAvailableNum.min){
            customToast.error({msg: "사진 선택관련 데이터에 문제가있습니다.\n계약하신 지점으로 문의해주세요."});
            return ;
        }

        if(photoProductOptionDataList.length> 0 && selectedOptionList.length === 0){
            customToast.error({msg: "옵션을 선택해주세요.(필수 택 1)"});
            return ;
        }
        
        if(!customAddressData.is_set){
            customToast.error({msg: "배송지를 입력해주세요."});
            return ;
        }

        const final_msg_list: string[] = ["상품제작을 위한 사진을 모두 선택하셨습니까?", "제품 제작이 시작되면 입력하신 내용과 선택하신 사진 변경이 불가능합니다."];
        if(!window.confirm(final_msg_list.join(`\n`))){
            return ;
        }else if(!window.confirm(`사진선택을 완료하시겠습니까?`)){      
            return ;
        }      

        if(production && folderList){
            const toast_id: string = `${production.contract_product_id}`;

            setIsUploading(true);
            
            const file_data: uploadRequestfileDataInterface[] = [];
            
            let master_file_url = `media/office/${production.office_id}/customers/${production.customer_id}/selectPhoto/contract/${production.contract_id}/products/${production.contract_product_id}`;
            
            if(isUseMultiFolder){
                master_file_url += "/folder";
            }
                
            for(const folder of folderList){

                let upload_url = master_file_url;
                if(folder.folder_id > 0){
                    upload_url += `/${folder.folder_id}`;
                }

                let pending_msg = "사진들을 업로드 중입니다...";
                let error_msg = "사진들의 업로드 과정에서 문제가 발생했습니다.";
                if(folder.folder_id > 0){
                    pending_msg = `${folder.folder_name} ${pending_msg}`;
                    error_msg = `${folder.folder_name} ${error_msg}`;
                }

                customToast.info({
                    msg: pending_msg,
                    autoClose: false,
                    toastId: toast_id
                })
                            
                try{
                    //  이미지를 청크로 업로드하는 부분
                    const result = await imageFilesUpload({
                        files: imgFiles[folder.folder_id],
                        file_dir: upload_url,
                    })
    
                    if(result.state === 200){
                        const result_data:chunkImageUploadDataInterface[] = result.data;
                        for(const data of result_data){
                            file_data.push({
                                file_url: upload_url,
                                file_name: data.file_name,
                                file_ext: data.file_extension,
                                file_size: data.file_size,
                            })
                        }
                    }else{
                        customToast.error({
                            msg: error_msg,
                        })
    
                        break ;
                    }

                }catch(error){

                    customToast.error({
                        msg: error_msg,
                    })

                    break ;
                }
            }

            toast.dismiss(toast_id);

            customToast.info({
                msg: "거희 완료됬습니다.",
                autoClose: false,
                toastId: toast_id
            })

            let baby_birth:string = "";
            if(albumData.baby_birth){
                baby_birth = formatDate(albumData.baby_birth, "yyyy-MM-dd");
            }

            let birth_time: string = "";
            if(albumData.baby_birth_time){
                birth_time = formatDate(albumData.baby_birth_time, "HH:mm");
            }

            // 입력한 아기정보를 스토리지에 저장
            const m_baby_birth_date_time = new Date(`${baby_birth} ${birth_time}`);
            const m_babyinfo: albumDataInterface = {
                baby_name: albumData.baby_name,
                baby_birth: m_baby_birth_date_time,
                baby_birth_time: m_baby_birth_date_time,
                tall: `${albumData.tall}`,
                weight: `${albumData.weight}`,
            }
            userDataStorageManager.babyInfo.set(m_babyinfo);

            // 입력한 주소를 스토리지에 저장
            const address: addressDataInterface = customAddressData;
            userDataStorageManager.address.set(address);

            const config = {
                contract_product_id: production.contract_product_id,
                contract_id: `${production.contract_id}`,
                office_id: production.office_id,
                album_save_id: isReRequest ? `${production.album_save_id}` : "",
                thema_id_list: selectedOptionList.map((item) => item.thema_id).join(","),

                baby_name: albumData.baby_name,
                baby_birth,
                birth_time,
                height: `${albumData.tall}`,
                weight: `${albumData.weight}`,
                
                addr: address.mainAddress,
                addr_detail: address.detailAddress,
                building_no: address.buildingCode,
                zip_code: address.zonecode,
                common_memo: request,
                master_file_url: master_file_url,
                file_data: file_data,
            }

            dev_console.log(config);

            // 모든 이미지의 업로드가 완료된 후, 업로드한 이미지들에대한 정보를 서버로 보낸다.
            const photo_select_upload_new_url = "api/v3/customers/photo-select-upload-new";
            callAxios.api({
                url: photo_select_upload_new_url,
                method: "post",
                dataType: "json",
                data: config,
            })
            .then(async (response) => {
                dev_console.log(photo_select_upload_new_url);
                dev_console.log(response);
                
                const response_data: defaultApiReaponseInterface<undefined> = response.data;
                
                if(isSuccess(response)){
                    // 데이터들을 새로 다시 요청한다.
                    await photoProductDataListRefetchAll();
                    await photoProductDataListRefetchNeedRequest();
                    await photoProductDataListRefetchRequestDone();

                    // alert("요청이 완료되었습니다.");
                    customToast.success({
                        msg: "요청이 완료되었습니다",
                    })

                    navigate("/productionDetail", {
                        state:{
                            contract_product_id: production.contract_product_id,
                        }
                    });
                }else if(response_data.status.code === 201){
                    customToast.error({msg: "사진 재선택이 불가능한 상품입니다.\n사유 : 이미 제품 제작이 시작된 상품입니다."});
                }else{
                    printStateMsg(response);
                    customToast.error({msg: "사진 업로드에 실패했습니다."})
                }
            })
            .catch((error)=>{
                dev_console.error(error);
                customToast.error({msg: "사진 업로드 과정에서 문제가 발생했습니다."})
            })
            .finally(()=>{
                setIsUploading(false);
                toast.dismiss(toast_id);
            })
        }
    };

    // 스크롤 액션 함수
    const scrollToFirstFalseItem = (folder_id: number) => {        
        if (!!cardRefs.current[folder_id]) {
            // 스크롤 이동
            cardRefs.current[folder_id]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };  

    return(
        <div className={CSS.l_production_request_main}>
            <ProductionRequestThemplete 
                folderList={folderList}
                imgFiles={imgFiles}
                setImgFiles={setImgFiles}
                isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                setIsSatisfiedPhotoNums={setIsSatisfiedPhotoNums}
                photoProductOptionDataList={photoProductOptionDataList}
                selectedOptionList={selectedOptionList}
                setSelectedOptionList={setSelectedOptionList}
                isAlbumProduct={isAlbumProduct}
                albumData={albumData}
                setAlbumData={setAlbumData}
                request={request}
                setRequest={setRequest}
                customAddressData={customAddressData}
                setCustomAddressData={setCustomAddressData}
                setRowAddressData={setRowAddressData}
                handleUpload={handleUpload}
                isReRequest={isReRequest}
                cardRefs={cardRefs}
                contract_product_id={contract_product_id}
        
                isReadOnly={false}

                isUseMultiFolder={isUseMultiFolder}

                album_save_id={album_save_id}
            />
            <LoopAnimationModal 
                isModalOpen={isUploading}
            />
        </div>
    )
}

export default ProductionRequest;