import { dev_console, getAccountData } from "../../../../../../Common/ts/commonTools";
import { addressDataInterface } from "../../../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { albumDataInterface } from "../../../../static/interface/ProductionList.interface";

class localStorageManagerClass{
    parent: userDataStorageManagerClass;
    token: string;
    user_id: number;
    user_login_id: string

    constructor(parent: userDataStorageManagerClass) {
        this.parent = parent;
        this.token = getAccountData.token();
        this.user_id = getAccountData.user_id();
        this.user_login_id = getAccountData.oops_id();
    }

    set<T>(
        {
            key,
            data,
        }:{
            key: string, 
            data: T
        }
    ){
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            const m_string_data = JSON.stringify(data);

            if(m_string_data.length > 0){
                localStorage.setItem(m_key, m_string_data);

                return({
                    key: m_key,
                    data,
                })
            }else{
                return null;
            }
        }catch(error){
            dev_console.error(error);
            return null;
        }
    }

    get<T>(key: string): T | null{
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            const m_reuslt_data = localStorage.getItem(m_key);
    
            if(m_reuslt_data){
                return (JSON.parse(m_reuslt_data));
            }else{
                return null;
            }
        }catch(error){
            dev_console.error(error);
            return null;
        }
    }

    remove(key: string){
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            localStorage.removeItem(m_key);
            return true;
        }catch(error){
            dev_console.error(error);
            return false;
        }
    }
}

export class userDataStorageManagerClass{
    localStorageManager: localStorageManagerClass;

    // babyInfo와 address를 속성으로 추가
    babyInfo: {
        get: () => albumDataInterface | null;
        set: (data: albumDataInterface) => any;
        remove: () => boolean;
    };

    address: {
        get: () => addressDataInterface | null;
        set: (data: addressDataInterface) => any;
        remove: () => boolean;
    };

    constructor() {
        this.localStorageManager = new localStorageManagerClass(this);
        
        // babyInfo 객체 정의
        this.babyInfo = {
            get: () => {
                const RTN = this.localStorageManager.get<albumDataInterface>("babyInfo");

                // JSON.parse로 복구하더라도 Date객체는 문자열인상태로 남아있게된다. 따라서 이를 복구하는 로직이 별도로 필요하다.
                if(RTN && RTN.baby_birth){
                    let baby_birth: Date = new Date(RTN.baby_birth);
                    
                    if(isNaN(baby_birth.getTime())){
                        RTN.baby_birth = null;
                    }else{
                        RTN.baby_birth = baby_birth;
                    }
                }

                if(RTN && RTN.baby_birth_time){
                    let m_baby_birth_time: Date = new Date(RTN.baby_birth_time);

                    if(isNaN(m_baby_birth_time.getTime())){
                        RTN.baby_birth_time = null;
                    }else{
                        RTN.baby_birth_time = m_baby_birth_time;
                    }
                }

                return RTN;
            },
            set: (data: albumDataInterface) => {
                return this.localStorageManager.set<albumDataInterface>({
                    key: "babyInfo",
                    data,
                });
            },
            remove: () => {
                return this.localStorageManager.remove("babyInfo");
            },
        };
        
        // address 객체 정의
        this.address = {
            get: () => {
                return this.localStorageManager.get<addressDataInterface>("address");
            },
            set: (data: addressDataInterface) => {
                return this.localStorageManager.set<addressDataInterface>({
                    key: "address",
                    data,
                });
            },
            remove: () => {
                return this.localStorageManager.remove("address");
            },
        };
    }
}