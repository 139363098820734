import { getYear } from "date-fns";
import { range } from "lodash";

export const MONTHS = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
];

export const YEARS = range(1990, getYear(new Date()) + 1, 1);