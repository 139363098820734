import React, { useEffect, useState, forwardRef } from "react";
import CSS from "./static/css/input.module.css";
import ToggleIconBtn from "./components/ToggleIconBtn";
import View_icon from "./static/img/View.svg";
import View_hide_icon from "./static/img/View_hide.svg";

interface InputProps {
    type?: React.HTMLInputTypeAttribute;
    value?: string | number | readonly string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    size?: "size1_harf" | "size1" | "size2" | "size3" | "size_full" | "auto";
    readOnly?: boolean;
    disabled?: boolean;
    minLength?: number;
    maxLength?: number;
    max?: number;
    min?: number;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    name?: string;
    id?: string;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    placeholder?: string;
    checked?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
    type = "text",
    value = "",
    onChange,
    size,
    readOnly = false,
    disabled = false,
    minLength,
    maxLength,
    max,
    min,
    onBlur,
    name,
    id,
    onKeyDown,
    placeholder,
    checked,
}, ref) => {
    const [totalClassName, setTotalClassName] = useState<string>("");
    const [totalInputClassName, setTotalInputClassName] = useState<string>("");
    const [isViewText, setIsViewText] = useState<boolean>(false);

    useEffect(() => {
        const total_class_name_list: string[] = [CSS.l_input_main];
    
        if (size && CSS[size]) {
            total_class_name_list.push(CSS.size);
            total_class_name_list.push(CSS[size]);
        }

        if(type){
            total_class_name_list.push(CSS.type);
            total_class_name_list.push(CSS[type]);
        }

        setTotalClassName(total_class_name_list.join(" "));
    }, [size, type])

    useEffect(() => {
        const total_class_name_list: string[] = [CSS.l_input];

        if (type) {
            total_class_name_list.push(CSS.type);
            if (CSS[type]) {
                total_class_name_list.push(CSS[type]);
            }
        }

        if(disabled || readOnly){
            total_class_name_list.push(CSS.disabled);
        }

        setTotalInputClassName(total_class_name_list.join(" "));
    }, [type, disabled, readOnly]);

    return (
        <div className={totalClassName}>
            {
                type && type === "password"
                &&  <ToggleIconBtn
                        is_enable={isViewText}
                        onClick={() => {
                            setIsViewText((is_view_text)=>!is_view_text)
                        }}
                        enable_img_url={View_icon}
                        disable_img_url={View_hide_icon}
                    />
            }

            <input 
                className={totalInputClassName}
                ref={ref}
                value={value}
                type={
                    type && type === "password"
                    ?   isViewText
                        ?   "text"
                        :   "password"
                    :   type
                }
                onChange={onChange}
                readOnly={readOnly}
                disabled={disabled}
                minLength={minLength}
                maxLength={maxLength}
                max={max}
                min={min}
                onBlur={onBlur}
                name={name}
                id={id}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                checked={checked}
                step="any"
            />
        </div>
    );
});

export default Input;