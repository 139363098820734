import { useEffect, useRef, useState } from "react";
import ProductionRequestThemplete from "./components/ProductionRequestThemplete/ProductionRequestThemplete";
import { useLocation, useNavigate } from "react-router-dom";
import { scheduleProductInterface } from "./static/interface/ProductionList.interface";
import useProductionRequestinitialData from "./hooks/useProductionRequestinitialData.hook";
import useProductionBtnState from "./hooks/useProductionBtnState.hook";
import Button from "../../Component/Elements/Button/Button";
import CSS from "./static/css/ProductionDetail.module.css";
import usePhotoProductDataREST from "./hooks/usePhotoProductDataREST.hook";

const ProductionDetail = () => {
    // 각 폴더들에 대한 ref들 -> 최종적으로 서버에 요청을 보내서 저장할 때 스크롤을 컨트롤해야하기 때문에 외부에서 관리
    const cardRefs = useRef<Array<HTMLDivElement | null>>([]); 

    const navigate = useNavigate();

    const location = useLocation();

    // 제품 정보가 없을경우 내보낸다.
    if(!(!!location.state) || !(!!location.state?.contract_product_id)){
        window.location.href = "/productionList";
    }

    const {
        contract_product_id,
    } = location.state as {
        contract_product_id: number;
    };

    if(!(!!contract_product_id) || contract_product_id < 1){
        window.location.href = "/productionList";
    }

    // 전체 - 카테고리의 데이터들 refetch함수
    const {
        photoProductDataList,
    } = usePhotoProductDataREST({
        search_type: 0
    });

    let production = photoProductDataList.find((item) => item.contract_product_id === contract_product_id);

    if(!(!!production)){
        window.location.href = "/productionList";
    }

    // 위의 조건들을 통과했다면 production이 빈값일 가능성은 없으므로 자료형을 교정해준다.
    production = production as scheduleProductInterface;

    const {
        selectedOptionList, 
        setSelectedOptionList, 
        request, 
        setRequest, 
        isSatisfiedPhotoNums, 
        setIsSatisfiedPhotoNums, 
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        albumData, 
        setAlbumData,
        choicesAvailableNum,
        isAlbumProduct,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        folderList,
        imgFiles, 
        setImgFiles,
        isUseMultiFolder,
    } = useProductionRequestinitialData(production);

    const { btnState } = useProductionBtnState(production);

    const reRequestBtnHandler = () => {
        if(!window.confirm("요청사항을 변경하실경우 사용하실 사진들을 다시 올려주셔야합니다.\n진행하시겠습니까?")){
            return ;
        }

        navigate("/productionRequest", {
            state: {
                production: production,
                isReRequest: true,
            }
        })
    }

    return(
        <div className={CSS.l_production_detail_main}>
            <ProductionRequestThemplete 
                folderList={folderList}
                imgFiles={imgFiles}
                isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                photoProductOptionDataList={photoProductOptionDataList}
                selectedOptionList={selectedOptionList}
                isAlbumProduct={isAlbumProduct}
                albumData={albumData}
                request={request}
                customAddressData={customAddressData}
                setCustomAddressData={setCustomAddressData}
                
                cardRefs={cardRefs}

                isReadOnly={true}

                isUseMultiFolder={isUseMultiFolder}

                album_save_id={production.album_save_id || 0}

                is_show_after_change_address_btn={production.goout === "N"}

                contract_product_id={contract_product_id}
            >
                {
                    btnState === 5
                    &&  <div className="g_btn_wrap">
                            <Button
                                size={"size_full"}
                                purpose={"save"}
                                onClick={reRequestBtnHandler}
                            >
                                {"요청사항 변경"}
                            </Button>
                        </div>
                }
            </ProductionRequestThemplete>
        </div>
    )
}

export default ProductionDetail;