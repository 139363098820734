import { themaBookDetailListInterface } from "../../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { albumDataInterface, scheduleProductFolderInterface } from "../../static/interface/ProductionList.interface";
import InputTitle from "../InputTitle/InputTitle";
import OptionsContainer from "../OptionsContainer/OptionsContainer";
import AlbumProductInputContainer from "../AlbumProductInputContainer/AlbumProductInputContainer";
import RequestTextArea from "../RequestTextArea/RequestTextArea";
import AddressContainer from "../AddressContainer/AddressContainer";
import Button from "../../../../Component/Elements/Button/Button";
import CSS from "./static/css/ProductionRequestThemplete.module.css";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { Address } from "react-daum-postcode";
import { useState } from "react";
import AddressUpdateModal from "../AddressUpdateModal/AddressUpdateModal";
import FolderImageViewer from "../../../../Component/Elements/FolderImageViewer/FolderImageViewer";

const ProductionRequestThemplete = (
    {
        folderList,
        imgFiles, 
        setImgFiles,
        isSatisfiedPhotoNums,
        setIsSatisfiedPhotoNums,
        photoProductOptionDataList,
        selectedOptionList,
        setSelectedOptionList,
        isAlbumProduct,
        albumData,
        setAlbumData,
        request,
        setRequest,
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        handleUpload,
        
        isReRequest,
        
        cardRefs,

        isReadOnly,

        isUseMultiFolder,

        is_show_after_change_address_btn,

        album_save_id,

        children,
    }:{
        folderList: scheduleProductFolderInterface[];
        imgFiles: {[key: number]: File[];};
        setImgFiles?: React.Dispatch<React.SetStateAction<{[key: number]: File[];}>>;
        isSatisfiedPhotoNums: {[key:number]: boolean;};
        setIsSatisfiedPhotoNums?: React.Dispatch<React.SetStateAction<{[key:number]: boolean;}>>;
        photoProductOptionDataList: themaBookDetailListInterface[];
        selectedOptionList: themaBookDetailListInterface[];
        setSelectedOptionList?: React.Dispatch<React.SetStateAction<themaBookDetailListInterface[]>>;
        isAlbumProduct: boolean;
        albumData: albumDataInterface;
        setAlbumData?: React.Dispatch<React.SetStateAction<albumDataInterface>>;
        request: string;
        setRequest?: React.Dispatch<React.SetStateAction<string>>;
        customAddressData: addressDataInterface;
        setCustomAddressData: React.Dispatch<React.SetStateAction<addressDataInterface>>;
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        handleUpload?: () => Promise<void>;
        contract_product_id: number;
        
        isReRequest?: boolean;

        cardRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;

        isReadOnly?: boolean;

        isUseMultiFolder: boolean;

        is_show_after_change_address_btn?: boolean;

        album_save_id: number | null;
        
        children?: React.ReactNode;
    }
) => {
    const [isOpenAddressResetModal, setIsOpenAddressResetModal] = useState<boolean>(false);

    const modelOpenHandler = () => {
        setIsOpenAddressResetModal(true);
    }

    const modalCloseHandler = () => {
        setIsOpenAddressResetModal(false);
    }

    return(
        <div className={CSS.l_uploaded_image_wrap_modal_main}>
            <div className={CSS.l_uploaded_image_wrap_modal__main_container}>
                <FolderImageViewer 
                    folderList={folderList}
                    imgFiles={imgFiles}
                    setImgFiles={setImgFiles}
                    isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                    setIsSatisfiedPhotoNums={setIsSatisfiedPhotoNums}
                    isUseMultiFolder={isUseMultiFolder}
                    isReadOnly={isReadOnly}
                    cardRefs={cardRefs}
                />

                {
                    photoProductOptionDataList.length > 0
                    &&  <div className={CSS.l_uploaded_image_wrap_modal__option_container}>
                            <InputTitle 
                                title="옵션"
                                sub_title="필수선택(택 1)"
                            />
                            <div className={CSS.l_uploaded_image_wrap_modal__option_container__option_item_container}>
                                <OptionsContainer
                                    selectedProductOptionDataList={photoProductOptionDataList}
                                    selectedOptionList={selectedOptionList}
                                    setSelectedOptionList={setSelectedOptionList}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>
                }
                <div className={CSS.l_uploaded_image_wrap_modal__input_container}>
                    {
                        isAlbumProduct
                        &&  <AlbumProductInputContainer
                                albumData={albumData}
                                setAlbumData={setAlbumData}
                                disabled={isReadOnly}
                            />
                    }

                    <RequestTextArea 
                        request={request}
                        setRequest={setRequest}
                        disabled={isReadOnly}
                    />

                    <AddressContainer 
                        customAddressData={customAddressData}
                        setCustomAddressData={setCustomAddressData}
                        setRowAddressData={setRowAddressData}
                        disabled={isReadOnly}
                    >
                        {
                            is_show_after_change_address_btn
                            &&  <Button
                                    purpose={"cancel"}
                                    size={"auto"}
                                    onClick={modelOpenHandler}
                                >
                                    배송지 변경
                                </Button>
                        }
                    </AddressContainer>

                    { children }

                    {
                        (!isReadOnly && handleUpload)
                        &&  <Button
                                class_name_list={[CSS.l_upload_btn]}
                                size={"size_full"}
                                purpose={"save"}
                                onClick={handleUpload}
                            >
                                {
                                    isReRequest
                                    ?   "재신청"
                                    :   "제품 제작 신청"
                                }
                            </Button>
                    }
                </div>
            </div>

            {/* 컴포넌트가 소멸되면 내부의 state값이 초기화되는것을 활용하기위해 isOpenAddressResetModal조건에따라 모달 컴포넌트 자체를 소멸시킨다. */}
            {
                isOpenAddressResetModal
                &&  <AddressUpdateModal
                        album_save_id={album_save_id}
                        isModalOpen={isOpenAddressResetModal}
                        modalCloseHandler={modalCloseHandler}
                        initialCustomAddressData={customAddressData}
                    />
            }
        </div>
    )
}

export default ProductionRequestThemplete;