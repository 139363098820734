import { useEffect, useState } from "react";
import { Address } from "react-daum-postcode";
import { addressDataInterface } from "../interface/useDaumPostcode.interface";

const useDaumPostcode = (data: Address | null) => {
    
    const [rowAddressData, setRowAddressData] = useState<Address | null>(data);
    const [customAddressData, setCustomAddressData] = useState<addressDataInterface>({
        is_set: false,
        zonecode: "",
        mainAddress: "",
        sigunguCode: "",
        bcode: "",
        bname: "",
        buildingName: "",
        buildingCode: "",
        userSelectedType: "R",
        detailAddress: "",
    });
    
    useEffect(() => {
        if(rowAddressData){
            setCustomAddressData((pre) => {
                return({
                    ...pre,
                    is_set: true,
                    zonecode: rowAddressData.zonecode,
                    mainAddress: rowAddressData.roadAddress,
                    sigunguCode: rowAddressData.sigunguCode,
                    bcode: rowAddressData.bcode,
                    bname: rowAddressData.bname,
                    buildingName: rowAddressData.buildingName,
                    buildingCode: rowAddressData.buildingCode,
                    userSelectedType: rowAddressData.userSelectedType,
                })
            })
        }
    }, [rowAddressData])

    return (
        {
            customAddressData,
            setCustomAddressData,
            setRowAddressData,
        }
    )
}

export default useDaumPostcode;