import { useId } from "react";
import CSS from "./static/css/albumInput.module.css";
import Input from "../../../../Component/Elements/Input/Input";

const AlbumInput = (
    {
        label,
        value,
        onChange,
        type,
        disabled,
        min,
        max,
        customInput,
        customId,
    }:{
        label?: string;
        value?: string | number;
        onChange?: React.ChangeEventHandler<HTMLInputElement>
        type?: React.HTMLInputTypeAttribute;
        disabled?: boolean;
        min?: number;
        max?: number;
        customInput?: React.ReactNode;
        customId?: string;
    }
) => {
    const id = useId();

    return (
        <div className={`g_input_wrap ${CSS.l_album_input_main}`}>
            {
                label
                &&  <label htmlFor={customId || id}>
                        {label}
                    </label>
            }
            <div className={CSS.l_input_container}>
                {
                    customInput
                    ?   customInput
                    :   <Input 
                            value={value}
                            id={id}
                            onChange={onChange}
                            type={type}
                            disabled={disabled}
                            size={"size1"}
                            min={min}
                            max={max}
                        />
                }
            </div>
        </div>
    )
}

export default AlbumInput;