import { useEffect, useState } from "react";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import useDaumPostcode from "../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import { albumDataInterface, scheduleProductInterface } from "../static/interface/ProductionList.interface";
import useProductionInfo from "./useProductionInfo.hook";

const useProductionRequestinitialData = (production: scheduleProductInterface) => {
    // 선택한 옵션
    const [selectedOptionList, setSelectedOptionList] = useState<themaBookDetailListInterface[]>([]);
    
    // 요청사항
    const [request, setRequest] = useState<string>("");

    // 모든 폴더에서 선택해야할 사진 수를 만족했는지 검증하기위한 함수
    const [isSatisfiedPhotoNums, setIsSatisfiedPhotoNums] = useState<{[key:number]: boolean;}>({})

    // 주소
    const {
        customAddressData,
        setCustomAddressData,
        setRowAddressData, 
    } = useDaumPostcode(null);

    // 앨범 데이터
    const [albumData, setAlbumData] = useState<albumDataInterface>({
        baby_name: "",
        baby_birth: null,
        tall: "",
        weight: "",
        baby_birth_time: null,
    })

    // 업로드된 이미지 데이터(key값은 folder의 id로 처리한다)
    const [imgFiles, setImgFiles] = useState<{[key: number]: File[];}>({});

    const {
        choicesAvailableNum,
        isAlbumProduct,
        initialAlbumData,
        initialRequest,
        initialCustomAddressData,
        initialSelectedOptionList,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        folderList,
    } = useProductionInfo(production);

    // 여러개의 폴더별로 처리하는 경우인가?
    const [isUseMultiFolder, setIsUseMultiFolder] = useState<boolean>(false);

    useEffect(() => {
        setCustomAddressData(initialCustomAddressData)
    }, [initialCustomAddressData])

    useEffect(() => {
        // 기존에 작성된 엘범 정보
        setAlbumData(initialAlbumData);
    }, [initialAlbumData])

    useEffect(() => {
        // 기존에 선택된 옵션 정보를 반영
        setSelectedOptionList(initialSelectedOptionList);
    }, [initialSelectedOptionList])

    useEffect(() => {
        // 기존에 작성된 요청사항
        setRequest(initialRequest);
    }, [initialRequest])

    // 사진선택 만족 상태를 초기화해서 생성
    useEffect(() => {
        if(folderList.length > 0 && folderList[0].folder_id > 0){
            setIsUseMultiFolder(true);
        }else{
            setIsUseMultiFolder(false);
        }

        const list:{[key:number]: boolean} = {}

        for(const foler of folderList){
            list[foler.folder_id] = false;
        }

        setIsSatisfiedPhotoNums(list);
    }, [folderList])

    return({
        selectedOptionList, 
        setSelectedOptionList, 
        request, 
        setRequest, 
        isSatisfiedPhotoNums, 
        setIsSatisfiedPhotoNums, 
        customAddressData,
        setCustomAddressData,
        setRowAddressData, 
        albumData, 
        setAlbumData,
        choicesAvailableNum,
        isAlbumProduct,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        folderList,
        imgFiles, 
        setImgFiles,
        isUseMultiFolder,  
        album_save_id: production.album_save_id,
        contract_product_id: production.contract_product_id,
    })
}

export default useProductionRequestinitialData;