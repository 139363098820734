import { progressScheduleInterface } from "../static/interface/reservationHome.interface"
import ScheduleBlock from "./ScheduleBlock/ScheduleBlock";
import Table from "../../../Component/Elements/Table/Table";
import TextFlexWrap from "../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import WideCard from "../../../Component/Elements/WideCard/WideCard";
import { getTimeDifferenceInMinutes } from "../../../Common/ts/commonTools";
import Dday from "./element/Dday";
import CSS from "../static/css/scheduleList.module.css";
import LoopAnimation from "../../../Component/Elements/LoopAnimation/LoopAnimation";
import Button from "../../../Component/Elements/Button/Button";
import { useNavigate } from "react-router-dom";
import Tooltip from "../../../Component/Elements/Tooltip/Tooltip";
import { setProgressScheduleBtn } from "../static/ts/reservation.tool";
import CardContainer from "../../../Component/Elements/CardContainer/CardContainer";
import Modal from "../../../Component/Elements/Modal/Modal";
import { useState } from "react";
import TextareaWrap from "./TextareaWrap/TextareaWrap";

const ScheduleList = (
    {
        progressSchedule,
        selectedSchedule,
        // setSelectedSchedule,
        isLoading,
    }:{
        progressSchedule: progressScheduleInterface[];
        selectedSchedule: progressScheduleInterface | null;
        // setSelectedSchedule: React.Dispatch<React.SetStateAction<progressScheduleInterface | null>>;
        isLoading: boolean;
    }
) => {

    const [isMemoPageModal, setIsMemoPageModal] = useState<boolean>(false);
    const [memo, setMemo] = useState<string>("");

    const navigate = useNavigate();

    // const clickEventHandler = (item: progressScheduleInterface) => {
    //     setSelectedSchedule(item);
    // }

    const modalOpenHandler = () => {
        setIsMemoPageModal(true);
    }

    const modalCloseHandler = () => {
        setIsMemoPageModal(false);
    }

    return (                        
        <div className={CSS.l_schedule_list_main}>
            {
                isLoading
                ?   <WideCard>
                        <LoopAnimation />
                    </WideCard>
                :   progressSchedule.length > 0
                    ?   <>  
                            {/* 모바일일때 출력하는 방식 */}
                            <CardContainer 
                                class_name_list={["g_display_none_object", "web", "flex", CSS.l_schedule_card_list_container]}
                                padding={"none"}
                                background_color={"ligth_gray"}
                            >
                                {
                                    progressSchedule.map((item, index) => {
                                        return (
                                                <ScheduleBlock 
                                                    key={index}
                                                    schedule={item}
                                                />
                                        )
                                    })
                                }
                            </CardContainer>

                            {/* 웹일때 출력하는 방식 */}
                            <CardContainer 
                                class_name_list={["g_display_none_object", "mobile", CSS.l_schedule_list_container]}
                                padding={"none"}
                            >
                                <Table 
                                    header_item_list={[
                                        "",
                                        "촬영 명칭",
                                        "촬영 종류",
                                        "촬영 일자",
                                        "촬영 시간",
                                        "계약 번호",
                                        "선택 테마",
                                        // "진행 상태",
                                        "",
                                        ""
                                    ]}
                                    body_item_list={
                                        progressSchedule.map((item) => {
                                            const result = setProgressScheduleBtn({item: item, navigate})
                                            const d_day_number: number = parseInt(item.d_day_num);
                                            
                                            const photo_time_list: string[] = [];
                                            if(item.detail){
                                                photo_time_list.push(item.detail.photo_start);
                                                photo_time_list.push("-");
                                                photo_time_list.push(item.detail.photo_end);
                                                
                                                const photo_lenth = getTimeDifferenceInMinutes(`${item.detail.photo_start}`, `${item.detail.photo_end}`, true);
                                                if(photo_lenth > -1){
                                                    photo_time_list.push(`(${photo_lenth} 분)`);
                                                }
                                            }

                                            let preparation_item: React.ReactNode = <div></div>;
                                            if(item.detail && item.detail.memo.length > 0){
                                                const memo: string = item.detail.memo || "";
                                                preparation_item = (
                                                                        <Tooltip
                                                                            isUseIcon={true}
                                                                            // tooltip_node={
                                                                            //     <Text
                                                                            //         size={"size3"}
                                                                            //     >
                                                                            //         {item.detail.memo}
                                                                            //     </Text>
                                                                            // }
                                                                            // onClick={clickEventHandler.bind(null, item)}
                                                                            onClick={() => {
                                                                                setMemo(memo);
                                                                                modalOpenHandler();
                                                                            }}
                                                                        >
                                                                            전달사항
                                                                        </Tooltip>
                                                                    )
                                            }

                                            return ([
                                                <Dday
                                                    d_day={d_day_number}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                />,
                                                <TextFlexWrap 
                                                    text_list={[item.detail?.schedule_title || ""]}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    text_list_justify_content={"center"}
                                                />,
                                                <TextFlexWrap 
                                                    text_list={[item.photo_type_nm]}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    text_list_justify_content={"center"}
                                                />,
                                                <TextFlexWrap
                                                    text_list={[item.schedule_date_format]}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    text_list_justify_content={"center"}
                                                />,
                                                <TextFlexWrap 
                                                    text_list={photo_time_list}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    text_list_justify_content={"center"}
                                                />,
                                                <TextFlexWrap 
                                                    text_list={[item.detail?.contract_id || ""]}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    text_list_justify_content={"center"}
                                                />,
                                                <TextFlexWrap 
                                                    text_list={item.detail?.thema_list_name || []}
                                                    // onClick={clickEventHandler.bind(null, item)}
                                                    is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                    separator={", "}
                                                    text_list_justify_content={"center"}
                                                />,
                                                // <TextFlexWrap 
                                                //     text_list={[`${item.status_text}`]}
                                                //     is_highlight={item.schedule_id === selectedSchedule?.schedule_id}
                                                //     text_list_justify_content={"center"}
                                                // />,
                                                preparation_item,
                                                <Button
                                                    class_name_list={[CSS.l_table_td_btn_main]}
                                                    onClick={result.onClick_btn}
                                                    purpose={result.purpose}
                                                >
                                                    {result.btn_title}
                                                </Button>
                                            ])
                                        })
                                    }
                                />
                            </CardContainer>
                        </>
                    :   <WideCard>
                            촬영 일정 정보가 없습니다.
                        </WideCard>
            }

            <Modal
                isModalOpen={isMemoPageModal}
                modalCloseHandler={modalCloseHandler}
                isBackgroundClose={true}
            >
                <TextareaWrap
                    label={""}
                    text={memo}
                />
            </Modal>
        </div>
    )
}

export default ScheduleList;