import { useMemo, useState } from "react";
import { getMonth, getYear } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { MONTHS, YEARS } from "./static/ts/const";
import "react-datepicker/dist/react-datepicker.css";
import "./static/css/react-datepicker-custom-style.css";
import { ko } from 'date-fns/locale';
import { dev_console } from "../../../Common/ts/commonTools";

export const DatePicker = (
    {
        dateValue,
        onChange,
        id,
        disabled,
    }:{
        dateValue: Date | null;
        onChange: (date: Date | null) => void;
        id?: string;
        disabled?: boolean;
    }
) => {
    const maxDate = useMemo(() => {
        return new Date("9999-12-31");
    }, [])

    return (
        <ReactDatePicker
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    
                    <select
                        value={getYear(date)}
                        onChange={({target:{value}}) => {
                            let m_value_num: number = parseInt(value);
                            changeYear(m_value_num)
                        }}
                    >
                        {
                            YEARS.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))
                        }
                    </select>
        
                    <select
                        value={MONTHS[getMonth(date)]}
                        onChange={({target:{value}}) =>
                            changeMonth(MONTHS.indexOf(value))
                        }
                    >
                        {
                            MONTHS.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))
                        }
                    </select>
        
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}

            selected={dateValue}

            onChange={onChange}

            todayButton="오늘"
            dateFormat={["yyyy-MM-dd", "yyyyMMdd", "yyyy MM dd", "yyyy/MM/dd"]}
            maxDate={maxDate}
            id={id}
            className={"react-datepicker-ignore-onclickoutside"}
            disabled={disabled}
            locale={ko}
            isClearable={!disabled}
        />
    );
}

export const TimePicker = (
    {
        dateValue,
        onChange,
        id,
        disabled,
    }:{
        dateValue: Date | null;
        onChange: (date: Date | null) => void;
        id?: string;
        disabled?: boolean;
    }
) => {
    return (
        <ReactDatePicker
            selected={dateValue}
            onChange={onChange}
            dateFormat={[
                "HH:mm", 
                "HHmm",
            ]}
            id={id}
            className={"react-datepicker-ignore-onclickoutside"}
            disabled={disabled}
            locale={ko}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            showTimeCaption={false}
            isClearable={!disabled}
        />
    );
}