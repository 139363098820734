import { useEffect, useState } from "react";
import { albumDataInterface, choicesAvailableNumInterface, scheduleProductFolderInterface, scheduleProductInterface } from "../static/interface/ProductionList.interface";
import { getMinMaxPhoto, isAlbum } from "../static/ts/productionListTools";
import useDaumPostcode from "../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import usePhotoProductOptionDataListREST from "./usePhotoProductOptionInfoDataListREST.hook";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { addressSetter, babyInfoSetter } from "../static/ts/productInfo.tools";

const useProductionInfo = (initial_production: scheduleProductInterface) => {

    // 선택할수 있는 사진의 수
    const [choicesAvailableNum, setChoicesAvailableNum] = useState<choicesAvailableNumInterface>({
        min: 0,
        max: 0,
    });

    const [isAlbumProduct, setIsAlbumProduct] = useState<boolean>(false);

    // 앨범 데이터
    const [initialAlbumData, setInitialAlbumData] = useState<albumDataInterface>({
        baby_name: "",
        baby_birth: null,
        tall: "",
        weight: "",
        baby_birth_time: null,
    })

    // 요청사항
    const [initialRequest, setInitialRequest] = useState<string>("");

    // 주소
    const { customAddressData, setCustomAddressData } = useDaumPostcode(null);

    // 기존에 선택한 옵션 정보
    const [initialSelectedOptionList, setinitialSelectedOptionList] = useState<themaBookDetailListInterface[]>([]);

    //  옵션 정보
    const {
        photoProductOptionDataList,
        photoProductOptionDataListError,
        photoProductOptionDataListIsLoading,
    } = usePhotoProductOptionDataListREST(initial_production);

    // 폴더 수
    const [folderList, setFolderList] = useState<scheduleProductFolderInterface[]>([]);
    
    useEffect(() => {
        if(initial_production.detail && initial_production.detail.length > 0){
            setChoicesAvailableNum(getMinMaxPhoto(initial_production.detail[0]));
        }

        // 아기정보 input 초기화
        const m_babyInfo_initial_data = babyInfoSetter(initial_production);
        setInitialAlbumData(m_babyInfo_initial_data)

        setInitialRequest(initial_production.common_memo || "");

        // 주소정보 초기화
        const m_adress_initial_data = addressSetter(initial_production);
        setCustomAddressData((item) => {
            return({
                ...item,
                zonecode: m_adress_initial_data.zonecode,
                mainAddress: m_adress_initial_data.mainAddress,
                detailAddress: m_adress_initial_data.detailAddress,
                buildingCode: m_adress_initial_data.buildingCode,
                is_set: m_adress_initial_data.is_set,
            })
        })

        const selected_product_opiton_id_list = [initial_production.option_id];
        const result = photoProductOptionDataList.filter((item) => selected_product_opiton_id_list.includes(`${item.thema_id}`));
        
        if(!!result){
            setinitialSelectedOptionList(result);
        }else{
            setinitialSelectedOptionList([]);
        }

        setIsAlbumProduct(isAlbum(initial_production.product_type));

        setFolderList(initial_production.folder_list);

    }, [initial_production, photoProductOptionDataList])

    return({
        choicesAvailableNum,
        isAlbumProduct,
        initialAlbumData,
        initialRequest,
        initialCustomAddressData: customAddressData,
        initialSelectedOptionList,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        folderList,
    })
}

export default useProductionInfo;