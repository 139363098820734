import { userDataStorageManagerClass } from "../../components/AlbumProductInputContainer/static/ts/AlbumProductInputContainer.tools";
import { albumDataInterface, scheduleProductInterface } from "../interface/ProductionList.interface";

const userDataStorageManager = new userDataStorageManagerClass();
 
export const babyInfoSetter = (initial_production: scheduleProductInterface): albumDataInterface => {

    const m_localStorage_babyInfo = userDataStorageManager.babyInfo.get();

    let m_baby_name: string = "";
    if(initial_production.name){
        m_baby_name = initial_production.name;
    }else if(m_localStorage_babyInfo && m_localStorage_babyInfo.baby_name){
        m_baby_name = m_localStorage_babyInfo.baby_name;
    }

    let m_baby_birth: Date | null = null;
    if(initial_production.birth){
        m_baby_birth = new Date(initial_production.birth);
    }else if(m_localStorage_babyInfo && m_localStorage_babyInfo.baby_birth){
        m_baby_birth = m_localStorage_babyInfo.baby_birth;
    }

    let m_baby_birth_time: Date | null = null;
    if(initial_production.birth_time){
        m_baby_birth_time = new Date(`1990-01-01 ${initial_production.birth_time}`);
    }else if(m_localStorage_babyInfo && m_localStorage_babyInfo.baby_birth_time){
        m_baby_birth_time = m_localStorage_babyInfo.baby_birth_time;
    }

    let m_tall: string = "";
    if(initial_production.height){
        m_tall = initial_production.height;
    }else if(m_localStorage_babyInfo && m_localStorage_babyInfo.tall){
        m_tall = m_localStorage_babyInfo.tall;
    }

    let m_weight: string = "";
    if(initial_production.weight){
        m_weight = initial_production.weight;
    }else if(m_localStorage_babyInfo && m_localStorage_babyInfo.weight){
        m_weight = m_localStorage_babyInfo.weight;
    }

    return{
        baby_name: m_baby_name,
        baby_birth: m_baby_birth,
        baby_birth_time: m_baby_birth_time,
        tall: m_tall,
        weight: m_weight,
    }
}

interface addressSetterRTNInterface{
    zonecode: string;
    mainAddress: string;
    buildingCode: string;
    is_set: boolean;
    detailAddress: string;
}
export const addressSetter = (initial_production: scheduleProductInterface): addressSetterRTNInterface => {
    const m_localStorage_address = userDataStorageManager.address.get();
    
    let m_zonecode: string = "";
    if(initial_production.zip_code){
        m_zonecode = initial_production.zip_code;
    }else if(m_localStorage_address && m_localStorage_address.zonecode){
        m_zonecode = m_localStorage_address && m_localStorage_address.zonecode;
    }
    
    let m_mainAddress: string = "";
    if(initial_production.addr){
        m_mainAddress = initial_production.addr;
    }else if(m_localStorage_address && m_localStorage_address.mainAddress){
        m_mainAddress = m_localStorage_address.mainAddress;
    }

    let m_detailAddress: string = "";
    if(initial_production.addr_detail){
        m_detailAddress = initial_production.addr_detail;
    }else if(m_localStorage_address && m_localStorage_address.detailAddress){
        m_detailAddress = m_localStorage_address.detailAddress;
    }
    
    let m_buildingCode: string = "";
    if(initial_production.building_no){
        m_buildingCode = initial_production.building_no;
    }else if(m_localStorage_address && m_localStorage_address.buildingCode){
        m_buildingCode = m_localStorage_address.buildingCode;
    }

    let m_is_set: boolean = false;
    if(initial_production.zip_code && initial_production.addr && initial_production.building_no){
        m_is_set = true;
    }else if(m_localStorage_address && m_localStorage_address.is_set){
        m_is_set = m_localStorage_address.is_set;
    }

    return({
        zonecode: m_zonecode,
        mainAddress: m_mainAddress,
        detailAddress: m_detailAddress,
        buildingCode: m_buildingCode,
        is_set: m_is_set
    })
}