import { useLocation, useNavigate } from "react-router-dom";
import edrp_oops_logo from "../../Common/image/EDRP-OOPS-02.png"

import hamberger_icon from "./static/img/menu-01.svg";

import { logout } from "../../Common/ts/appTools";

import CSS from "./static/css/header.module.css";
import Button from "../../Component/Elements/Button/Button";
import HistoryBackBtn from "./components/HistoryBackBtn";
import { getBackPath } from "./static/ts/Header.tools";
import { getAccountData, isNotPrintModeView, isUseTestData, whatIsMode, whoAmI } from "../../Common/ts/commonTools";
import Text from "../../Component/Elements/Text/Text";

const Header = (
    {
        setIsOpenMenu,
    }:{
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {

    const location = useLocation();

    const navigate = useNavigate();

    const result = whatIsMode();

    const logoutBtnHandler = () => {
        if(!window.confirm("로그아웃 하시겠습니까?")){
            return ;
        }

        logout();
    }

    return (
        <header className={CSS.l_header_main}>
        
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,

                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",

                    pointerEvents: "none", 
                }}
            >
                {
                    result.is_not_product_mode_safe && !isNotPrintModeView()
                    &&  <Text 
                            size={"size7"}
                            color={"mint"}
                        >
                            {result.mode_name}
                        </Text>
                }

                {
                    isUseTestData() && !isNotPrintModeView()
                    &&  <Text 
                            size={"size7"}
                            color={"mint"}
                        >
                            테스트데이터 적용중
                        </Text>
                }

                {
                    result.is_not_product_mode_safe && !isNotPrintModeView()
                    &&  <Text 
                            size={"size7"}
                            color={"mint"}
                        >
                            {result.get_api_url}
                        </Text>
                }
            </div>

            <div className={`${CSS.l_header_left}`}>
                {
                    getBackPath(location.pathname)
                    ?   <HistoryBackBtn />
                    :   <div
                            className="g_click_event_item g_display_none_object web"
                            onClick={() => {
                                setIsOpenMenu((is_open_menu)=>!is_open_menu);
                            }}
                        >
                            <img 
                                src={hamberger_icon} 
                            />
                        </div> 
                }
            </div>

            <div  
                className={`${CSS.l_header_center} g_click_event_item`}              
                onClick={() => {
                    navigate("/");
                }}
            >
                <div className={`${CSS.l_header_logo}`}>
                    <img 
                        src={edrp_oops_logo}
                        style={{
                            height: "100%"
                        }}
                    />
                </div>
            </div>

            <div className={`${CSS.l_header_right}`}>
                {
                    whoAmI.isAdmin()
                    &&  <>
                            <div>
                                {getAccountData.brand_name()}
                            </div>
                            |
                            <div>
                                {getAccountData.office_name()}
                            </div>
                            |
                            <div>
                                {getAccountData.name()}님
                            </div>
                            <Button
                                size={"size1"}
                                purpose={"delete"} 
                                onClick={logoutBtnHandler}
                            >
                                로그아웃
                            </Button>
                        </>
                }

                {
                    whoAmI.isCustomer()
                    &&  <>
                            <div 
                                className="g_click_event_item"
                                onClick={() => {
                                    navigate("/myInfo");
                                }}
                            >
                                {getAccountData.name()}님
                            </div>
                        </>
                }
            </div>
        </header>
    )
}

export default Header;